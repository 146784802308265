<template>

  <body>
    <div class="User_profile">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-4">
            <div class="user-card border-bottom">
              <div class="usr-profile-data">
                <img :src="companies.image" alt="avatar-1" class="user-profile-img" />
                <h6 class="text-h6">{{ companies.name }}</h6>
                <button type="button" class="btn btn-light disabled">{{ companies.website }}</button>
              </div>
            </div>
            <div class="detaisl-tx">
              <div class="heading-tx">
                <p class="text-sm text-uppercase text-disabled"> Details </p>
              </div>
              <div class="user-list-dta">
                <div class="list-tx">
                  <strong class="name-tx"> Name : </strong> <span class="selina">{{ companies.name }}</span>
                </div>
                <div class="list-tx">
                  <strong class="name-tx"> Billing Email : </strong> <span class="selina">{{ companies.email }}</span>
                </div>
                <div class="list-tx">
                  <strong class="name-tx"> Status : </strong> <span class="selina">
                    <button type="button" class="btn btn-light">Active</button>
                  </span>
                </div>
                <!-- <div class="list-tx">
                  <strong class="name-tx"> Role : </strong> <span class="selina">User</span>
                </div>
                <div class="list-tx">
                  <strong class="name-tx"> Tax ID : </strong> <span class="selina">Tax-8894</span>
                </div> -->
                <div class="list-tx">
                  <strong class="name-tx"> Contact : </strong> <span class="selina">{{ companies.phone_no }}</span>
                </div>
                <div class="list-tx">
                  <strong class="name-tx"> Language : </strong> <span class="selina">English</span>
                </div>
                <div class="list-tx">
                  <strong class="name-tx"> Country : </strong> <span class="selina">{{ companies.country_name }}</span>
                </div>
              </div>
              <div class="bottom-grp">
                <!-- <button type="button" class="btn btn-primary dfsd"> Edit </button> -->
                <router-link class="btn btn-primary dfsd" :to="{ name: 'company-edit', params: { id: company_id } }">
                  Edit </router-link>
                <!-- <button type="button" class="btn btn-secondary dfgfdsds"> Suspend </button> -->
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-8">
            <ul class="nav nav-pills mb-3 tabList" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active border mr-3" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                  role="tab" aria-controls="pills-home" aria-selected="true">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                    role="img" tag="i" class="v-icon notranslate v-theme--light me-1 iconify iconify--tabler"
                    width="1em" height="1em" viewBox="0 0 24 24" style="font-size: 18px; height: 18px; width: 18px;">
                    <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2">
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M3 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2m1-10l2 2l4-4"></path>
                    </g>
                  </svg>
                  <span class="user-name"> Overview </span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link border" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                  aria-controls="pills-profile" aria-selected="false">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                    role="img" tag="i" class="v-icon notranslate v-theme--light me-1 iconify iconify--tabler"
                    width="1em" height="1em" viewBox="0 0 24 24" style="font-size: 18px; height: 18px; width: 18px;">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"
                      d="M16.7 8A3 3 0 0 0 14 6h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1-2.7-2M12 3v3m0 12v3">
                    </path>
                  </svg>
                  <span class="user-name"> Purchased offers </span>
                </a>
              </li>
            </ul>
            <div class="offer_create_link">
              <!-- <router-link class="btn btn-primary dfsd" :to="{ name: 'offers-create' }"> Add Offer </router-link> -->
              <!-- <button type="button" click="test()" class="btn btn-primary dfsd" style="color: blue;">Add Offer</button> -->
              <b-button class="btn btn-primary dfsd" @click="addOffer()" variant="outline-primary">Add Offer</b-button>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="overview-div table-responsive">
                  <div class="header-hd">
                    <h1>Companies Offer List</h1>
                  </div>
                  <table class="table">
                    <thead>
                      <tr class="lsit-demodfd ">
                        <th scope="col">OFFER TITLE</th>
                        <th scope="col">
                          <div class="dfdsj">
                            OFFER CODE
                          </div>
                        </th>
                        <th scope="col">DESCRIPTION</th>
                        <th scope="col">VALID TILL</th>
                        <th scope="col">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="offer in CompaniesOffer">
                        <tr style="height: 3.75rem;">
                          <td>
                            <div class="d-flex align-center">
                              <div class="v-avatar v-avatar--density-default v-avatar--variant-flat me-3"
                                style="width: 38px; height: 38px;">
                                <div class="v-responsive v-img" aria-label="">
                                  <img :src="offer.image" alt="avater" class="table-img" />
                                </div>
                                <span class="v-avatar__underlay"></span>
                              </div>
                              <div class="user-contetn-dfd">
                                <p class="text-base mb-0">{{ offer.title }}</p>
                                <p class="text-sm text-disabled mb-0">Companies Offer</p>
                              </div>
                            </div>
                          </td>
                          <td class="text-medium-emphasis th-text-color">{{ offer.offer_code }}</td>
                          <td class="th-text-color">{{ offer.short_description.substring(0,30)+"...."  }}</td>
                          <td>{{ offer.valid_date }}</td>
                          <td>
                            <div class="actionDiv">
                              <router-link class="btn btn-primary editOffer"
                                :to="{ name: 'offers-edit', params: { id: offer.id } }">EDIT</router-link>
                              <b-button class="btn btn-danger deleteOffer" @click="deleteEmployee(offer.id)"
                                variant="outline-primary">DELETE</b-button>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <!-- <div class="current-palan">
                  <div class="header-hdas">
                    <h1>Current Plan</h1>
                  </div>
                  <div class="command-div-dfdnfl">
                    <div class="details-div-df">
                      <div class="divone-left">
                        <div class="dfsdfsdkl-df">Your Current Plan is Basic</div>
                        <div class="dkfjdf-dfdkfd">A simple start for everyone </div>
                      </div>
                      <div class="divone-left">
                        <div class="dfsdfsdkl-df">Active until Dec 09, 2021</div>
                        <div class="dkfjdf-dfdkfd">We will send you a notification upon Subscription expiration </div>
                      </div>
                      <div class="divone-left">
                        <div class="dfsdfsdkl-df">$199 Per Month
                          <span class="df-button">
                            <button type="button" class="btn btn-light " style="color: blue;">Popular</button>
                          </span>
                        </div>
                        <div class="dkfjdf-dfdkfd">Standard plan for small to medium businesses</div>
                      </div>
                    </div>
                    <div class="djfkdf-dfds-right">
                      <div class="divright-tx">
                        <div class="we-need">
                          <p class="ddfd-dfdk">We need your attention!</p>
                          <p class="dfdkjfd-dfdklndl">Your plan requires update</p>
                        </div>
                        <div class="helgnfff-dfnfd">
                          <div class="dskbfkds-dfdk">
                            <div class="left-number">Days</div>
                            <div class="left-number">26 of 30 Days</div>
                          </div>
                          <div class="process-slider">
                            <div class="v-progress-linear__background bg-success" style="width: 100%;"></div>
                            <div class="v-progress-linear__determinate bg-success"
                              style="width: 78%; background-color: blue!important;"></div>
                          </div>
                          <p class="dfndksf-dfdk">6 days remaining until your plan requires update</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-grp dsfndsjk-dfd">
                    <button type="button" class="btn btn-primary dfsd"> upgrade plan </button>
                    <button type="button" class="btn btn-secondary dfgfdsds"> Cancel Subscription </button>
                  </div>
                </div> -->
                <div class="overview-div table-responsive">
                  <div class="header-hd">
                    <h1>Offers Billing</h1>
                  </div>
                  <table class="table">
                    <thead>
                      <tr class="lsit-demodfd ">
                        <th scope="col">USER NAME</th>
                        <th scope="col">USER EMAIL</th>
                        <th scope="col">
                          <div class="dfdsj">
                            OFFER AMOUNT
                          </div>
                        </th>
                        <th scope="col">COMPANY SHARING AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="offer in CompaniesOfferBilling">
                        <tr style="height: 3.75rem;">
                          <td>
                            <div class="d-flex align-center">
                              <div class="v-avatar v-avatar--density-default v-avatar--variant-flat me-3"
                                style="width: 38px; height: 38px;">
                                <div class="v-responsive v-img" aria-label="">
                                  <img src="../../assets/user-icon.svg" alt="avater" class="table-img" />
                                </div>
                                <span class="v-avatar__underlay"></span>
                              </div>
                              <div class="user-contetn-dfd">
                                <p class="text-base mb-0">{{ offer.username }}</p>
                                <p class="text-sm text-disabled mb-0">USER PURCHASED OFFER</p>
                              </div>
                            </div>
                          </td>
                          <td class="text-medium-emphasis th-text-color">{{ offer.useremail }}</td>
                          <td class="th-text-color">{{ offer.offer_amount }}</td>
                          <td>{{ offer.company_sharing_amount }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>

import axios from "axios";
import Vue from 'vue'
import { db, storage } from '@/main'
import firebase from 'firebase';
import store from '@/store'

//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  required, email, confirmed, password, image, size, unique
} from '@validations'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormDatalist,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BFormFile,
  BFormSelect,
  BImg,
  BContainer
} from 'bootstrap-vue'

import Multiselect from 'vue-multiselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Create',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BImg,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    validate,
    required,
    email,
    confirmed,
    password,
    image,
    size,
    unique,
    Multiselect,

  },
  data: function () {
    return {
      company_id: '',
      companies: {},
      imageData: null,
      picture: null,
      uploadValue: 0,
      comLogoImg: '',
      comBackgroundImg: '',
      uploadValuech: 0,
      loading: false,
      locations: "",
      access_token: "pk.eyJ1IjoiaW50cm9zYnMiLCJhIjoiY2t2MHU4bWE4M2h1OTMwczcxMm03YmM0eSJ9.ocDaQEjuvL3rUd50oaB6Qw",
      center: [0, 0],
      map: {},
      name: '',
      email: '',
      asas: '',
      about: '',
      // image: '',
      background_image: '',
      location: '',
      country: '',
      maplocation: '',
      postcode: '',
      phone_no: '',
      lat: '',
      lang: '',
      website: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      previewLogoImg: '',
      previewBackImg: '',
      subscriptions: [],
      category: '',
      coordinates: [],
      CompaniesOffer: [],
      CompaniesOfferBilling: [],
      offers: {},
      subscription: '',
      finds: [],
      countries: [],
      country_code: '',
    }
  },
  // mounted() {

  //   this.createMap();
  // },
  created: function () {
    this.getCountries();
    this.getCompaniesOffer();
    this.getOfferBilling();
    this.getCompany();
    window.onpopstate = function () {
      localStorage.removeItem('company_id');
      localStorage.removeItem('company_country_id');
    };
  },

  methods: {

    addOffer() {
      // alert(this.companies.country_id)
      console.log(this.companies.country_id);
      this.company_id = this.$route.params.id;
      localStorage.setItem("company_id", this.company_id);
      localStorage.setItem("company_country_id", this.companies.country_id);
      this.$router.push({ name: 'offers-create' });
    },

    getCountries() {
      this.countries = [];

      db.collection("countries")
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.countries.push({
              id: doc.id,
              name: doc.data().country,
              slug: doc.data().slug,
            });
            //console.log(doc.id, " => ", doc.data());

          });


        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    getCompaniesOffer() {
      this.CompaniesOffer = [];
      this.company_id = this.$route.params.id;

      db.collection("offers")
        .where("company", "==", this.company_id)
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.offers = doc.data();
            this.CompaniesOffer.push({
              id: doc.id,
              title: doc.data().title,
              image: doc.data().image,
              offer_code: doc.data().offer_code,
              short_description: doc.data().short_description,
              valid_date: doc.data().valid_date,
            });
            console.log(doc.id, " => ", doc.data());
          });


        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

    },

    getOfferBilling() {
      this.CompaniesOfferBilling = [];
      this.company_id = this.$route.params.id;

      db.collection("offer_purchase")
        .where("company_id", "==", this.company_id)
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.offers = doc.data();
            this.CompaniesOfferBilling.push({
              id: doc.id,
              username: doc.data().firstname + ' ' + doc.data().firstname,
              useremail: doc.data().useremail,
              offer_amount: doc.data().amount,
              company_sharing_amount: doc.data().company_sharing_amount,
              // short_description: doc.data().short_description,
              // valid_date: doc.data().valid_date,
            });
            console.log(doc.id, " => ", doc.data());
          });


        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

    },

    getSubscriptions() {
      this.subscriptions = [];

      db.collection("subscriptions")
        .get()
        .then((querySnapshot) => {
          console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.subscriptions.push({
              id: doc.id,
              name: doc.data().title,
            });
            //console.log(doc.id, " => ", doc.data());

          });

          console.log(this.subscriptions);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },

    getCompany() {
      this.countriesSelect = [];
      var id = this.$route.params.id;
      // alert(id)
      db.collection('companies').doc(id).get().then((doc) => {
        console.log(doc.data());
        this.companies = doc.data();
        this.companies.country_id = doc.data().country;
        this.finds = doc.data().outlets;
        this.countriesSelect.push({
          id: doc.data().country,
          name: doc.data().country_name,
        });
        this.previewBackImg = doc.data().background_image;
        this.previewLogoImg = doc.data().image;

        console.log(doc.id, " => ", doc.data())
      })
    },

    deleteEmployee(id) {

      if (confirm("Do you really want to delete?")) {
        // alert('dddd');
        db.collection("offers")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            this.getCompaniesOffer();
            this.$toasted.success('Success, Deleted Successfully!', {
              icon: 'check',
              //icon : 'error_outline'
            })
            //this.$router.push('/blogs/index');

          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });

      }
    },
  }
}
</script>
<style scoped>
@import "../../assets/dashboard/css/companyViewProfile.css";

.offer_create_link {
  text-align: right;
  margin-bottom: 1rem !important;
}

.actionDiv {
  white-space: nowrap;
}

.editOffer {
  width: 50%;
  font-size: 10px;
  padding: 10px 10px 10px 10px;
}

.deleteOffer {
  width: 50%;
  font-size: 10px;
  padding: 10px 10px 10px 10px;
  margin: 10px;
}
</style>
